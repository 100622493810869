"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var SvgMiniMapIcon = function (props) { return (React.createElement("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", width: 16, height: 16, fill: "currentColor" }, props),
    React.createElement("path", { fill: props.fill || props.currentColor || "#262D42", d: "M8 7.334A.667.667 0 0 0 7.333 8v2c0 .368.298.667.667.667h4a.667.667 0 0 0 .666-.667V8A.667.667 0 0 0 12 7.333z" }),
    React.createElement("path", { fill: props.fill2 || props.currentColor2 || "#262D42", fillRule: "evenodd", d: "M3.333 3.334a2 2 0 0 0-2 2v5.333a2 2 0 0 0 2 2h9.333a2 2 0 0 0 2-2V5.334a2 2 0 0 0-2-2zm-.667 2c0-.369.299-.667.667-.667h9.333c.368 0 .667.298.667.667v5.333a.667.667 0 0 1-.667.667H3.333a.667.667 0 0 1-.667-.667z", clipRule: "evenodd" }))); };
exports.default = SvgMiniMapIcon;
